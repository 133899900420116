import React, { Fragment, useEffect, useState } from 'react';

import { Grid, Col, Row, Divider } from '@components/Grid';
import ImageSlider from '@components/ImageSlider';
import Button from '@components/Button';
import { addToBasket } from '@actions/basket';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { handleError } from '@helpers/errors';
import { getBasket } from '@helpers/store';
import { makePutRequest, makePostRequest } from '@helpers/requests';
import { BASKET_ADD, BASKET_CREATE } from '@helpers/api';
import Layout from '@components/Layout';
import ReactPixel from 'react-facebook-pixel';
import SEO from '../../components/SEO';
import { graphql } from 'gatsby';
if (typeof window !== 'undefined') {
    var navigate = require('gatsby').navigate;
}
const Product = ({
    data: {
        merlinWebsite: {
            websiteId,
            store: {
                currency: { symbol: currencySymbol, code: currencyCode },
            },
        },
        merlinStoreProduct: { _id: id, name, price, description, images, shortName },
        merlinStore: { products },
    },
    basketId,
    addToBasket,
}) => {
    const basket = getBasket(products);

    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            ReactPixel.track('ViewContent', {
                content_type: 'product',
                content_ids: [id],
                content_name: name,
                currency: currencyCode,
                value: parseFloat(price / 100),
            });
        }
    }, []);

    const handleAddToBasket = async () => {
        try {
            if (basketId) {
                await makePutRequest(
                    BASKET_ADD(basketId),
                    {
                        productId: id,
                        quantity: ((basket.find(item => item.id === id) || {}).quantity || 0) + 1,
                    },
                    { websiteId }
                );

                if (process.env.NODE_ENV === 'production') {
                    ReactPixel.track('AddToCart', {
                        content_type: 'product',
                        content_name: name,
                        content_ids: [id],
                        currency: currencyCode,
                        value: parseFloat(price / 100),
                    });
                }
                addToBasket({ id, quantity: 1 });
            } else {
                const { data: basketData } = await makePostRequest(
                    BASKET_CREATE,
                    { productId: id, quantity: 1 },
                    { websiteId }
                );

                if (process.env.NODE_ENV === 'production') {
                    ReactPixel.track('AddToCart', {
                        content_type: 'product',
                        content_ids: [id],
                        content_name: name,
                        currency: currencyCode,
                        value: parseFloat(price / 100),
                    });
                }

                addToBasket({ id, quantity: 1, basketId: basketData._id });
            }
        } catch (error) {
            handleError(error);
        }
    };

    const handlePurchase = async () => {
        try {
            handleAddToBasket();
            navigate('/store/basket');
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <Layout>
            <SEO
                product={{
                    id,
                    name,
                    image: images && images[0],
                    currency: currencySymbol,
                    price,
                }}
            />
            <Grid>
                <Row>
                    <Col xs={12} md={6}>
                        {!!images && !!images.length && (
                            <Fragment>
                                <ImageSlider>
                                    {images
                                        .filter(image => !!image && !!image.image)
                                        .map(({ image }, index) => (
                                            <ImageSlider.Item
                                                key={index}
                                                alt={name}
                                                fluid={image.childImageSharp.fluid}
                                            />
                                        ))}
                                </ImageSlider>
                                <Divider />
                            </Fragment>
                        )}
                    </Col>
                    <Col xs={12} md={6}>
                        <h1>{name}</h1>
                        {!!description && <p>{description}</p>}
                        <p>
                            <b>{`${currencySymbol}${parseFloat(price / 100).toFixed(2)}`}</b>
                        </p>
                        <Button primary text="Add to basket" onClick={() => handleAddToBasket()} />
                        <Button secondary text="Purchase" onClick={() => handlePurchase()} />
                    </Col>
                </Row>
            </Grid>
        </Layout>
    );
};

export default connect(
    ({ basket }) => ({ basketId: basket ? basket.id : null }),
    dispatch => bindActionCreators({ addToBasket }, dispatch)
)(Product);

export const query = graphql`
    query($id: String!) {
        merlinWebsite {
            websiteId
            store {
                currency {
                    symbol
                    code
                }
            }
        }
        merlinStoreProduct(_id: { eq: $id }) {
            _id
            name
            shortName
            description
            price
            images {
                image: url {
                    childImageSharp {
                        fluid(maxHeight: 600) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        merlinStore: allMerlinStoreProduct {
            products: nodes {
                _id
            }
        }
    }
`;
